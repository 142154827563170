<template>
  <v-container fluid>
    <v-col class="mt-0 pt-0 pb-0" cols="12">
      <v-card>
        <v-card-actions>
          <v-icon @click="back">mdi-arrow-left-circle</v-icon>

          <v-spacer></v-spacer>
          <v-btn @click="createVideo">
            <v-icon size="15" class="mr-1">mdi-microphone</v-icon>Record Video
          </v-btn>&nbsp;
          <upload
            v-bind:path="videoPath"
            classes="uploader-btn"
            v-on:uploadComplete="videoUploaded"
            buttonText="Upload Video"
          ></upload>
        </v-card-actions>
      </v-card>
    </v-col>

    <v-row>
      <v-dialog v-model="dialog" max-width="800">
        <v-form ref="form" @submit.prevent="validateBeforeSubmit">
          <v-card>
            <v-card-title class="subheading">Videos</v-card-title>
            <v-card-text>
              <v-text-field
                v-model="vm.title"
                label="Video title"
                v-validate="'required'"
                data-vv-as="title"
                name="title"
                :error-messages="errors.collect('title')"
              ></v-text-field>

              <v-textarea
                filled
                v-model="vm.description"
                label="Description"
                v-validate="''"
                :auto-grow="true"
                data-vv-as="description"
                name="description"
                :error-messages="errors.collect('description')"
              ></v-textarea>
              <video id="myVideo" class="video-js vjs-default-skin" playsinline></video>
              <v-progress-linear
                v-if="uploading && !uploadEnd"
                :size="100"
                :width="640"
                :value="progressUpload"
                color="primary"
              ></v-progress-linear>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn @click="close">Cancel</v-btn>
              <v-btn color="primary" type="submit">Save</v-btn>
            </v-card-actions>
          </v-card>
        </v-form>
      </v-dialog>
      <v-dialog v-model="videoEditDialog" max-width="800">
        <v-form ref="formEdit" @submit.prevent="validateBeforeSubmit">
          <v-card>
            <v-card-title class="subheading">Videos</v-card-title>
            <v-card-text>
              <v-col cols="12">
                <v-text-field
                  v-model="vm.title"
                  label="Video title"
                  v-validate="'required'"
                  data-vv-as="title"
                  name="title"
                  :error-messages="errors.collect('title')"
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-textarea
                  filled
                  v-model="vm.description"
                  label="Description"
                  v-validate="''"
                  rows="3"
                  data-vv-as="description"
                  name="description"
                  :error-messages="errors.collect('description')"
                ></v-textarea>
              </v-col>
              <v-col cols="12">
                <div v-for="(video, idx) in vm.videos" :key="idx">
                  <video width="320" height="240" controls>
                    <source :src="video.downloadURL" type="video/webm" />Your browser does not support the video tag.
                  </video>
                </div>
              </v-col>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn @click="close">Cancel</v-btn>
              <v-btn color="primary" type="submit">Save</v-btn>
            </v-card-actions>
          </v-card>
        </v-form>
      </v-dialog>
      <v-col cols="12">
        <v-card>
          <v-card-title class="subheading">
            Videos
            <v-spacer></v-spacer>
            <div></div>
          </v-card-title>

          <v-card-text>
            <v-data-table
              id="list-table"
              ref="datatable"
              :headers="headers"
              :items="videos"
              :loading="loading"
              item-key="index"
            >
              <template slot="item" slot-scope="props">
                <tr>
                  <td>
                    <span v-if="props.item.videos!=undefined && props.item.videos.length>0">
                      <a
                        :href="props.item.videos[0].downloadURL"
                        target="_blank"
                      >{{ props.item.title}}</a>
                    </span>
                    <span
                      v-if="props.item.videos===undefined ||props.item.videos.length==0"
                    >{{ props.item.title}}</span>
                  </td>
                  <td class="truncate">
                    <span>{{ props.item.description}}</span>
                  </td>
                  <td>
                    <span
                      v-if="props.item.createdOn!=undefined"
                    >{{ moment( props.item.createdOn.toDate()).format("DD-MM-YYYY")}}</span>
                  </td>
                  <td>{{ props.item.createdBy==undefined ? (props.item.modifiedBy===undefined? "":props.item.modifiedBy.displayName): props.item.createdBy.displayName}}</td>
                  <td class="justify-center layout px-0">
                    <v-icon small class="mr-2" @click="editItem(props.item)">mdi-pencil</v-icon>
                  </td>
                </tr>
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import "video.js/dist/video-js.css";
import "videojs-record/dist/css/videojs.record.css";
import Vue from "vue";
import videojs from "video.js";
import RecordRTC from "videojs-record/dist/videojs.record.js";
import firebase from "firebase";
import moment from "moment";
import VeeValidate from "vee-validate";
Vue.use(VeeValidate);

export default {
  name: "app",

  data: () => ({
    videoEditDialog: false,
    editedIndex: -1,
    player: "",
    videoSet: { videos: [] },
    currentVideo: {},
    uploadTask: {},
    videoPath: "",
    vm: {},
    progressUpload: 0,
    uploading: false,
    uploadEnd: false,
    options: {
      controls: true,
      autoplay: false,
      fluid: false,
      loop: false,
      width: 640,
      height: 480,
      frameWidth: 640,
      frameHeight: 480,
      plugins: {
        // configure videojs-record plugin
        record: {
          audio: false,
          video: true,
          debug: true
        }
      }
    },
    videos: [],
    rowPageConfig: [
      50,
      100,
      200,
      { text: "$vuetify.dataIterator.rowsPerPageAll", value: -1 }
    ],
    headers: [
      { text: "Title", value: "title" },
      { text: "Description", value: "Description" },
      { text: "Date recorded", value: "date" },
      { text: "Recorded by", value: "recordedBy" },
      { text: "", value: "" }
    ],

    totalItems: 0,
    loading: false,
    dialog: false
  }),
  mounted() {
    var me = this;
    this.videoPath = `${this.currentOrganisation.id}/${this.currentCase.id}/videos/`;
    this.$db
      .collection("organisations")
      .doc(this.currentOrganisation.id)
      .collection("cases")
      .doc(this.currentCase.id)
      .collection("videos")
      .get()
      .then(results => {
        const videos = [];
        results.docs.map(doc => {
          let videoSet = doc.data();
          videoSet.id = doc.id;
          videos.push(videoSet);
        });
        me.videos = videos.sort((ax, bx) => {
          if (ax.modifiedOn == undefined) {
            return 1;
          }
          if (bx.modifiedOn == undefined) {
            return -1;
          }
          let a = ax.modifiedOn.toDate();
          let b = bx.modifiedOn.toDate();
          return a > b ? -1 : a < b ? 1 : 0;
        });
      });
  },
  beforeDestroy() {
    if (this.player) {
      this.player.dispose();
    }
  },
  computed: {
    user() {
      return this.$store.getters.user;
    },
    currentCase() {
      return this.$store.getters.currentCase;
    },
    currentOrganisation() {
      return this.$store.getters.currentOrganisation;
    }
  },
  methods: {
    moment: function(date) {
      return date != undefined ? moment(date) : moment();
    },
    close() {
      this.dialog = false;
      this.videoEditDialog = false;
      setTimeout(() => {
        this.vm = {};
        this.editedIndex = -1;
        this.$forceUpdate();
      }, 300);
    },
    back() {
      this.$router.push("/participant-management/current-participant");
      setTimeout(() => {
        this.vm = {};
      }, 300);
    },
    createVideo() {
      this.vm = {};
      this.editedIndex = -1;
      this.$store.commit("setCurrentVideo", this.vm);

      this.dialog = true;
      /* eslint-disable no-console */
      setTimeout(() => {
        this.player = videojs("#myVideo", this.options, () => {
          // print version information at startup
          var msg =
            "Using video.js " +
            videojs.VERSION +
            " with videojs-record " +
            videojs.getPluginVersion("record") +
            " and recordrtc " +
            RecordRTC.version;
          videojs.log(msg);
        });

        // device is ready
        this.player.on("deviceReady", () => {
          console.log("device is ready!");
        });

        // user clicked the record button and started recording
        this.player.on("startRecord", () => {
          console.log("started recording!");
        });

        // user completed recording and stream is available
        this.player.on("finishRecord", () => {
          // the blob object contains the recorded data that
          // can be downloaded by the user, stored on server etc.
          //console.log("finished recording: ", this.player.recordedData);
          var storageService = firebase.storage();
          var storageRef = storageService.ref();
          var metadata = {
            contentType: "video/webm"
          };

          this.currentVideo = {
            lastModifiedDate: this.player.recordedData.lastModifiedDate,
            name: this.player.recordedData.name,
            size: this.player.recordedData.size,
            type: this.player.recordedData.type,
            refURL: `${this.videoPath}${this.player.recordedData.name}`,
            filename: this.player.recordedData.name
          };
          this.videoSet.videos.push(this.currentVideo);
          this.uploading = true;
          this.uploadTask = storageRef
            .child(this.currentVideo.refURL)
            .put(this.player.recordedData, metadata);
        });

        // error handling
        this.player.on("error", (element, error) => {
          console.warn(error);
        });

        this.player.on("deviceError", () => {
          console.error("device error:", this.player.deviceErrorCode);
        });
      }, 300);

      (this.videoSet = { videos: [] }), (this.dialog = true);
    },
    editItem(item) {
      this.vm = Object.assign({}, item);
      this.editedIndex = this.videos.indexOf(item);
      this.$store.commit("setCurrentVideo", this.vm);
      this.videoEditDialog = true;
    },
    deleteItem(item) {
      console.log(item);
    },

    validateBeforeSubmit() {
      const me = this;
      this.$validator.validateAll().then(result => {
        if (result) {
          var vidSet = me.$store.getters.currentVideoSet;
          if (vidSet.videos.length === 0) {
            console.error("no recordings");
          }
          vidSet.modifiedOn = new Date();
          vidSet.modifiedBy = me.user;
          if (vidSet.createdOn == undefined) {
            vidSet.createdOn = new Date();
          }
          if (vidSet.createdBy == undefined) {
            vidSet.createdBy = new Date();
          }

          vidSet.title = this.vm.title;
          vidSet.description = this.vm.description;
          this.$store.dispatch("addVideoSet", vidSet);
          if (me.editedIndex > -1) {
            Object.assign(me.videos[me.editedIndex], vidSet);
          }
          me.$forceUpdate();
          me.close();
          return;
        } else {
          this.$store.dispatch("setToast", {
            message: "Form errors please correct before continuing",
            color: "red darken-4"
          });
        }
      });
    },
    videoUploaded(data) {
      const videoSet = {
        title: data.filename,
        videos: [
          {
            lastModifiedDate: new Date(),
            name: data.filename,
            size: 0,
            type: "",
            downloadURL: data.downloadURL,
            refURL: `${this.videoPath}${data.filename}`,
            filename: data.filename
          }
        ]
      };

      this.videos.push(videoSet);
      this.$store.dispatch("addVideoSet", videoSet);
      this.$forceUpdate();
    }
  },
  watch: {
    uploadTask: function() {
      this.uploadTask.on(
        "state_changed",
        snapshot => {
          this.progressUpload = Math.floor(
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100
          );
          console.log("Upload is " + this.progressUpload + "% done");
        },
        null,
        () => {
          let me = this;
          this.uploadTask.snapshot.ref.getDownloadURL().then(downloadURL => {
            this.uploading = false;
            this.downloadURL = downloadURL;

            me.currentVideo.downloadURL = downloadURL;
            me.videos.push(me.videoSet);
            me.$store.dispatch("addVideoSet", me.videoSet);
          });
        }
      );
    }
  }
};
</script>

<style >
.truncate {
  max-width: 250px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>